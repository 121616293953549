import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import localize from "../components/_global/localization/localize";
import Layout from "../components/_global/layout/Layout";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Text from "../components/_global/editorial/Text";
import Spacer from "../components/_global/layout/Spacer";
import Block from "../components/_global/editorial/Block";
import Tag from "../components/_global/Tag";
import AltImage from "../components/_global/AltImage";
import Link from "../components/_global/localization/Link";
import RoundButton from "../components/_global/RoundButton";
import slugify from "slugify";

const Comp = styled(Section)`
  background-color: ${({ theme }) => theme.colors.titanWhite};
  box-shadow: 5px 5px 0 0 hsla(240, 38%, 95%, 1);
`;

const HeaderImage = styled(AltImage)`
  max-height: 30rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top center;
`;

const LinkList = styled.ul`
  list-style: disc;
  padding-left: 1rem;

  li {
    width: fit-content;
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;

const LearnFile = ({ data, ...props }) => {
	const { file, learn, general } = data;
	// console.log(file);
	const moreLinks = file.more.length > 0 ? file.more.map((item, index) => {
		return (
			<li key={index}>
					<Text s="medium">
						<Link to={item}>
						{item}
						</Link>
					</Text>

			</li>
		);
	}) : null;

	return (
		<Layout title={learn.common.title + ": " + file.title}>
			<PageHeader title={learn.common.title} />

			<Comp m="large">
				<HeaderImage data={file.image} />
				<Spacer s="medium" />
				<Link to={"learn#" + slugify(file.category.title)} style={{ alignSelf: "center" }}>
					<Tag>
						{file.category.title}
					</Tag>
				</Link>

				<Spacer s="medium" />
				<Text s="h2" c="main01">
					{file.title}
				</Text>
				<Spacer s="small" />

				{file.content ? <Block data={file.content} /> : null}
				<Spacer s="medium" />
				<Spacer s="small" />

				{moreLinks ?
					<>
						<Text s="h2" c="main01">
							{general.goFurther}
						</Text>
						<Spacer s="medium" />

						<LinkList>
							{moreLinks}
						</LinkList>
					</>
					: null}

				<Spacer s="large" />
			</Comp>
			<Spacer s="large" />

			<RoundButton style={{ alignSelf: "center" }} type="pill" data={general.seeAllLearnFiles} />
			<Spacer s="large" />
		</Layout>
	);
};

export default localize(LearnFile);

export const query = graphql`
    fragment LearnFileFragment on SanityLearnFile {
        slug {
            current
        }
        category {
            _id
            title {
                _type
                fr
            }
        }
        content {
            ...CompleteBlockFragment
        }
        description {
            _type
            fr
        }
        more
        image {
            ...ImageFragment
        }
        title {
            _type
            fr
        }
    }

    query($_id: String!) {
        learn: sanityLearn {
            common {
                title {
                    _type
                    fr
                }
            }
        }
        general: sanityGeneralComponent {
            goFurther {
                _type
                fr
            }
            seeAllLearnFiles {
                ...LinkFragment
            }
        }
        file: sanityLearnFile( _id: { eq: $_id } ) {
            ...LearnFileFragment
        }
    }
    #    query LearnFileQuery {
    #        sanityLearnFile(_id: {eq: "343da410-0774-4a8e-b19a-2aa1399adc37"}) {
    #            _id
    #        }
    #    }
`;